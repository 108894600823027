<template>
  <div>
       <b-card bg-variant="light" header="Datos Básicos" header-bg-variant="primary" header-text-variant="white" align="center" >
        <b-form @submit="modalEnviar" @reset="cancelar" align="left">

            <b-form-row>
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-licencia"
                        label="Número de Acta"
                        label-for="licencia"
                    >
                        <b-form-input
                        id="licencia"
                        v-model="form.num_acta"
                        :disabled="verDetalles?true:visitaEnGestion.num_acta!=null &&visitaEnGestion.num_acta!=''?true:false "
                        required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-fecha-registro"
                        label="Fecha Registro"
                        label-for="fecha-registro"
                    >
                        <b-form-datepicker 
                            id="fecha-registro"
                            v-model="form.fecha_registro"
                            placeholder="DD/MM/YYYY"
                            labelNoDateSelected="No hay fecha"
                            labelHelp="Use las flechas del teclado para navegar entre fechas"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :disabled="verDetalles?true:visitaEnGestion.fecha_registro!=null &&visitaEnGestion.fecha_registro!=''?true:false "
                        ></b-form-datepicker >
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-fecha-plan"
                        label="Fecha Plan"
                        label-for="fecha-plan"
                    >
                        <b-form-datepicker 
                            id="fecha-plan"
                            v-model="form.fecha_plan"
                            placeholder="DD/MM/YYYY"
                            labelNoDateSelected="No hay fecha"
                            labelHelp="Use las flechas del teclado para navegar entre fechas"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :disabled="verDetalles?true:visitaEnGestion.fecha_plan!=null &&visitaEnGestion.fecha_plan!=''?true:false "
                        ></b-form-datepicker >
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-hora"
                        label="Hora"
                        label-for="hora"
                    >
                        <b-form-timepicker 
                            id='hora' 
                            v-model="form.hora_visita" 
                            placeholder="HH:MM:SS" 
                            labelCloseButton = "Cerrar"
                            labelNoTimeSelected = "No hay hora"
                            locale="es" 
                            :disabled="verDetalles?true:visitaEnGestion.hora_visita!=null &&visitaEnGestion.hora_visita!=''?true:false "
                        ></b-form-timepicker>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-fecha-visita"
                        label="Fecha Visita"
                        label-for="fecha-visita"
                    >
                        <b-form-datepicker 
                            id="fecha-visita"
                            v-model="form.fecha_visita"
                            placeholder="DD/MM/YYYY"
                            labelNoDateSelected="No hay fecha"
                            labelHelp="Use las flechas del teclado para navegar entre fechas"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :disabled="verDetalles?true:false"
                            required
                        ></b-form-datepicker >
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-direccion"
                        label="Dirección"
                        label-for="direccion"
                    >
                        <b-form-input
                        id="direccion"
                        v-model="form.direccion"
                        :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-barrio"
                        label="Barrio/Vereda"
                        label-for="barrio"
                    >
                        <b-form-input
                        id="barrio"
                        v-model="form.barrio_vereda"
                        :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-coord_n"
                        label="Coordenadas GPS Norte"
                        label-for="coord_n"
                    >
                        <b-form-input
                        id="coord_n"
                        v-model="form.coord_n"
                        :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-coord_w"
                        label="Coordenadas GPS Oeste"
                        label-for="coord_w"
                    >
                        <b-form-input
                        id="coord_w"
                        v-model="form.coord_w"
                        :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-nombre_atiende"
                        label="Nombre atiende"
                        label-for="nombre_atiende"
                    >
                        <b-form-input
                            id="nombre_atiende"
                            v-model="form.nombre_atiende"
                            :disabled="verDetalles?true:false"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-iden_atiende"
                        label="Cédula atiende"
                        label-for="iden_atiende"
                    >
                        <b-form-input
                            id="iden_atiende"
                            v-model="form.iden_atiende"
                            :disabled="verDetalles?true:false"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-telefono_atiende"
                        label="Teléfono atiende"
                        label-for="telefono_atiende"
                    >
                        <b-form-input
                            id="telefono_atiende"
                            v-model="form.telefono_atiende"
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-email_atiende"
                        label="Email atiende"
                        label-for="email_atiende"
                    >
                        <b-form-input
                            id="email_atiende"
                            type="email"
                            v-model="form.email_atiende"
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-num_empleados"
                        label="Número de empleados"
                        label-for="num_empleados"
                    >
                        <b-form-input
                            id="num_empleados"
                            type="number"
                            v-model="form.num_empleados"
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="6">
                    
                    <b-form-group
                        id="group-horario_atencion"
                        label="Horario de atención"
                        label-for="horario_atencion"
                        
                    >
                        <b-form-checkbox class="mr-2  btn-horario" size="sm" v-model="form.horario_lv" button button-variant="primary" :disabled="verDetalles?true:false">Lunes a Viernes</b-form-checkbox>
                        <b-form-checkbox class="mr-2  btn-horario" size="sm" v-model="form.horario_s" button button-variant="primary" :disabled="verDetalles?true:false">Sábados</b-form-checkbox>
                        <b-form-checkbox class="mr-2  btn-horario" size="sm" v-model="form.horario_d" button button-variant="primary" :disabled="verDetalles?true:false">Domingos</b-form-checkbox>
                        <b-form-checkbox class="mr-2  btn-horario" size="sm" v-model="form.horario_724" button button-variant="primary" :disabled="verDetalles?true:false">7/24</b-form-checkbox> 
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group
                        id="group-objetivo"
                        label="Objetivo"
                        label-for="objetivo"
                    >
                        <b-form-textarea
                            id="group-objetivo"
                            v-model="form.objetivo"
                            placeholder="Objetivo"
                            rows="3"
                            max-rows="6"
                            :disabled="verDetalles?true:false"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group
                        id="group-observaciones"
                        label="Observaciones"
                        label-for="observaciones"
                    >
                        <b-form-textarea
                            id="group-observaciones"
                            v-model="form.observaciones"
                            placeholder="Observaciones"
                            rows="3"
                            max-rows="6"
                            :disabled="verDetalles?true:false"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button variant="primary" class="mr-2" v-if="verDetalles && !vigilanciaControl" @click="descargarPDFVisita" >
                        Descargar PDF
                    </b-button>
                    <b-button variant="primary" class="mr-2" v-if="verDetalles && vigilanciaControl" @click="descargarPDFVyC" >
                        Descargar PDF
                    </b-button>
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                    </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
      </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import {encontrarError} from '../../contantesErrores';
  export default {
    data() {
      return {
        usuario:{},
        vigilanciaControl:false,
        form: {
            id_licencia: '',
            id_empresa: null,
            id_usuario: '',
            fecha_registro: '',
            fecha_plan: '',
            fecha_visita: moment().format(),
            hora_visita: '',
            direccion: '',
            barrio_vereda: '',
            coord_n: '',
            coord_w: '',
            nombre_atiende: '',
            iden_atiende: '',
            telefono_atiende: '',
            email_atiende: '',
            horario_lv: false,
            horario_s: false,
            horario_d: false,
            horario_724: false,
            num_empleados: '',
            objetivo: '',
            observaciones: '',
            estado: '',
            id: null,
            num_acta:'',
        },
      }
    },
    mounted(){
        if(localStorage.getItem('usuario')){
            try {
                this.usuario = JSON.parse(localStorage.getItem('usuario'));
                this.form.id_usuario = this.usuario.id_usuario
            } catch(e) {
                localStorage.removeItem('usuario');
            }
        }

        if(this.$route.name=="datosBasicosVigilanciaControl"){
            this.vigilanciaControl = true;
        }
        if(this.visitaEnGestion.id){
            this.form= {...this.visitaEnGestion}
            this.form.fecha_visita=moment().format()
            this.form.horario_lv=this.form.horario_lv!=0&&this.form.horario_lv!=null?true:false
            this.form.horario_s=this.form.horario_s!=0&&this.form.horario_s!=null?true:false
            this.form.horario_d=this.form.horario_d!=0&&this.form.horario_d!=null?true:false
            this.form.horario_724=this.form.horario_724!=0&&this.form.horario_724!=null?true:false
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion', 'resultUpdateVisita', 'resultPDF', 'resultPDFVYC']),
    },
    methods: {
        ...mapActions('gestionarVisitas', ['actualizarDatosBasicos', 'guardarVisitaEnGestion', 'descargarPDF', 'descargarPDFVYC']),

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los Datos Básicos de la visita, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            let visita = {...this.form}
            visita.horario_lv=this.form.horario_lv==false?0:1
            visita.horario_s=this.form.horario_s==false?0:1
            visita.horario_d=this.form.horario_d==false?0:1
            visita.horario_724=this.form.horario_724==false?0:1
            visita.num_empleados=parseInt(this.form.num_empleados)
            visita.estado=1

            this.guardarVisitaEnGestion(visita)
            
            let datosBasicos = {
                visita: visita
            }
            try{
                await this.actualizarDatosBasicos(datosBasicos)
                if(this.resultUpdateVisita.data.status==200){
                    this.modalOk()
                }
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    if(this.vigilanciaControl){
                        this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                    }else{
                        this.$router.push({name: 'gestionVisita'})
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }, 

        async descargarPDFVisita(){
            try{
                await this.descargarPDF(this.visitaEnGestion.id)
                    console.log('aqui en la respuesta')
                if(this.resultPDF.status==200){
                    const linkSource = `data:application/pdf;base64,${this.resultPDF.data}`;
                    const downloadLink = document.createElement("a");
                    const fileName = `visita_${this.visitaEnGestion.id}.pdf`;
                
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        async descargarPDFVyC(){
            try{
                await this.descargarPDFVYC(this.visitaEnGestion.id)
                    console.log('aqui en la respuesta')
                if(this.resultPDFVYC.status==200){
                    const linkSource = `data:application/pdf;base64,${this.resultPDFVYC.data}`;
                    const downloadLink = document.createElement("a");
                    const fileName = `visita_${this.visitaEnGestion.id}.pdf`;
                
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            }catch(e){
                encontrarError(this, e.data)
            }
        }
    }
  }
</script>

<style scoped>
    
</style>